@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  src: url(fonts/openSansLatin400.woff2) format('woff2');
}

@font-face {
  font-family: 'OpenSans';
  font-style: bold;
  font-weight: 600;
  src: url(fonts/openSansLatin600.woff2) format('woff2');
}

@font-face {
  font-family: 'OpenSans';
  font-style: lighter;
  font-weight: 300;
  src: url(fonts/openSansLatin300.woff2) format('woff2'); 
}

body {
  --color-border: #ccc;
  --color-text: #444;
  --color-text-disabled: rgba(68, 68, 68, 0.658);
  --color-background: #e8ebe1;
  --color-interaction-1: rgb(81, 190, 154);
  --color-interaction-2: rgb(43, 116, 91);
  --color-interaction-3: rgb(7, 80, 55);
  --color-background-inverse: rgb(18, 172, 120);
  --color-background-inverse-hover: rgb(38, 204, 149);
  --color-input-border: rgba(111, 144, 236, 0.315);
  --color-input-border-hover: rgb(111, 144, 236);
  --color-input-border-focus:rgb(58, 98, 207);
  
  --color-status-error:rgb(182, 4, 4);

  margin: 0;
  font-family: 'OpenSans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: var(--color-background);    
  color: var(--color-text);


}

textarea {
  font-family: 'OpenSans', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


h3 {
  font-size: 20px;
}

a {
  color: var(--color-interaction-2);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:focus {
  text-decoration: underline;
  color: var(--color-interaction-3);
}